import { GalleryModalPage } from './gallery-modal/gallery-modal.page';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CodebaseModule } from './codebase/codebase.module';
import { CommonModule } from '@angular/common';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { SelectAvatarPageModule } from './codebase/select-avatar/select-avatar.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedComponentModule } from '@app/shared/shared.module';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { environment } from '@env/environment';
import { IFrameModalPage } from './i-frame-modal/i-frame-modal.page';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Network } from '@ionic-native/network/ngx';
import { HttpConfigInterceptor } from './helpers/http.interceptor';
import { SignInWithApple } from "@ionic-native/sign-in-with-apple/ngx";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from 'angularfire2/auth'

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Globalization } from '@ionic-native/globalization/ngx';
import { Push } from '@ionic-native/push/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `./themes/${environment.theme.name}/translations/`, '.json');
}

import * as firebase from 'firebase';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { StorageService } from './services/storage.service';
import { Storage } from '@ionic/storage-angular';

firebase.initializeApp(environment.firebase);
firebase.performance();
@NgModule({
  declarations: [AppComponent, IFrameModalPage, GalleryModalPage],
  entryComponents: [IFrameModalPage, GalleryModalPage],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot(),
    AppRoutingModule,
    SelectAvatarPageModule,
    IonicStorageModule.forRoot({
      name: environment.theme.name
    }),
    SharedComponentModule,
    CodebaseModule,
    HttpClientModule,
    ServiceWorkerModule.register('./combined-sw.js', { enabled: environment.production })
  ],
  providers: [
    FirebaseAnalytics,
    StatusBar,
    SplashScreen,
    SignInWithApple,
    Push,
    InAppBrowser,
    Geolocation,
    Globalization,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpConfigInterceptor,
    //   multi: true
    // },
    Network
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
